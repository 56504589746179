export enum RegionSearchTabsType {
    LOCATION,
    TRAVEL_TIME,
    DISTRICTS
}
export enum RegionSearchTabsType {
    LOCATION,
    TRAVEL_TIME,
    DISTRICTS
}
